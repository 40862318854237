import React, { FC, useEffect, useRef, useState } from 'react';
import Apollo13 from '../../static/apollo13.json';

const NotFoundPage: FC = () => {
  const indexRef = useRef(0);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentState = [...logs];
      currentState.push(Apollo13[indexRef.current]);
      indexRef.current += 1;
      setLogs(currentState);
      if (indexRef.current >= 4000) {
        indexRef.current = 100;
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [logs]);

  return (
    <div>
      <div className="error_ bcb cw">
        <div className="error_main abns top left x y f jcc abs aic cy">
          <h1 className="super h0 error__msg error__msg-big" data-text="404">
            404
          </h1>
        </div>
        <div className="error__return x y f jcs aie cw abs top left">
          <div>
            {logs.map((log, i) => (
              <div className="f jcs ais m0 p0" key={`${i}-e`}>
                <span
                  className={`akkura xsmall m0 p0 error__time rel ${(i + 10) %
                    (Math.random() * 14) >
                    7 && 'error__glitch'}`}
                  data-text={`${log.start}:${log.end}`}
                >
                  {log.start}:{log.end}:$
                </span>
                {log.source === 'Harry' ? (
                  <a
                    href="https://isaac.computer"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cy"
                  >
                    <span
                      className={`p0 m0 akkura xsmall ${(i + 10) %
                        (Math.random() * 14) >
                        7 && 'error__msg'} ${(i + 10) % (Math.random() * 20) >
                        7 && 'error__block'}`}
                      data-text={`${log.message}`}
                    >
                      {log.message}
                    </span>
                  </a>
                ) : (
                  <span
                    className={`p0 m0 akkura xsmall ${(i + 10) %
                      (Math.random() * 14) >
                      7 && 'error__msg'} ${(i + 10) % (Math.random() * 20) >
                      7 && 'error__block'}`}
                    data-text={`${log.message}`}
                  >
                    {log.message}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
